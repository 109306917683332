<template>
    <div class="premium-club-layout">
        <img class="premium-club-logo" src="@/components/assets/logoPC.svg" alt="">
        <div class="premium-club-layout-container">
            <div class="premium-club-slot">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
};
</script>
<style scoped>
h1,
p,
button,
input,
label,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.premium-club-layout {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 60px;
    min-height: 100vh;
}

.premium-club-logo {
    position: absolute;
    top: 12px;
    left: 32px;
    width: 100px;
}

.premium-club-layout-container {
    width: 100%;
    margin: 24px 32px;
    max-width: 1600px;
}

.premium-club-slot {
    width: 100%;
}

@media only screen and (max-width: 1500px) {
    .premium-club-logo {
        width: 80px;
    }
    .premium-club-logo {
        left: 16px;
    }
}

@media only screen and (max-width: 770px) {
    .premium-club-logo {
        position: fixed;
        z-index: 100;
    }
}
</style>

